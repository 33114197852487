import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './translations_json/en.json';
import ja from './translations_json/ja.json';
import ar from './translations_json/ar.json';
import { appEnvironment } from './common/environment';

const extraTranslations = {
  ['ko-KR']: {
    translation: en,
  },
  ['sw-KE']: {
    translation: en,
  },
  ['es-ES']: {
    translation: en,
  },
};

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'Default',
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',

    resources: {
      Default: {
        translation: en,
      },
      'ja-JP': {
        translation: ja,
      },
      'ar-001': {
        translation: ar,
      },
      ...(appEnvironment.addExtraTranslations ? extraTranslations : {}),
    },
  });

export default i18next;
